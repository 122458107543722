import { render, staticRenderFns } from "./generalInsForm.vue?vue&type=template&id=aae54590&scoped=true&"
import script from "./generalInsForm.vue?vue&type=script&lang=js&"
export * from "./generalInsForm.vue?vue&type=script&lang=js&"
import style0 from "./generalInsForm.vue?vue&type=style&index=0&id=aae54590&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aae54590",
  null
  
)

export default component.exports